import React from "react";

const HeroSection = ({ data }) => {
  return (
    <div className="hero text-white bg-category-page-mobile sm:bg-categorybg bg-center bg-no-repeat bg-cover h-72 min-h-full  flex flex-col justify-center  gap-5 text-center p-6 pt-56 pb-48 sm:h-72 sm:items-center lg:p-44">
        <h1 className="text-3xl font-bold text-left  sm:text-4xl mdcustom:text-[43px] mdcustom:leading-[46px] sm:text-center pb-4">{data?.Hero_Section?.Title}</h1>
        <p className="text-base font-normal text-left sm:text-lg sm:text-center mdcustom:max-w-[66%]">
        {data?.Hero_Section?.Description}
        </p>
     </div>
  );
};

export default HeroSection;
