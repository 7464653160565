import React from "react";
import PartnershipReasonsCard from "./PartnershipReasonsCard";
import PartnerCarousel from "../carousel/PartnerCarousel";

const PartnershipReasons = ({ data }) => {
  return (
    <div className="bg-[#F3F7FA]">
      <div className="reasons bg-[#F3F7FA] mx-3 mdcustom:mx-32 xl:mx-72 py-16">
        <h3 className="text-center text-2xl font-bold">
          {data?.Partnership_reason_title}
        </h3>
        <div className="carousel block mdcustom:hidden mt-8">
          <PartnerCarousel data={data} />
        </div>
        {data?.partnership_reasons?.data?.map((element, index) => (
          <PartnershipReasonsCard key={index} data={element} />
        ))}
      </div>
    </div>
  );
};

export default PartnershipReasons;
