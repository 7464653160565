import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Hero = ({ content }) => {
  return (
    <div className="hero h-[550px] bg-blogbanner bloghome-herobg bg-cover bg-center text-white mdcustom:h-[550px] ">
      <div className="wrapper flex flex-col justify-center  gap-8 h-full mx-10   pt-20 mdcustom:mr-0 mx-6 sm:mx-16 lg:mx-32 xlmd:mx-52">
        <h1 className="text-[34px] sm:text-4xl mdcustom:text-[42px] leading-[37px]  font-semibold bloghome-hero-title tablet:leading-[42px] mdcustom:max-w-[56%]">
          {content?.Title}
        </h1>
        <p className="text-base sm:text-lg mdcustom:text-xl font-normal text-[#E5E5E5] mdcustom:max-w-[52%]">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {content?.Description}
          </ReactMarkdown>
        </p>
      </div>
    </div>
  );
};

export default Hero;
