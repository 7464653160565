import React from 'react'

import CategoryCard from './CategoryCard';

const BlogCategoriesSection = ({data}) => {
  return (
    <div className="px-6 sm:px-16 lg:px-32 xlmd:px-52  py-5">
        <div className="text-black text-2xl font-bold mb-7 sm:mb-3 text-center tablet:text-left">
          Explore los artículos por categorías
        </div>
        <CategoryCard data={data} />
      </div>
  )
}

export default BlogCategoriesSection