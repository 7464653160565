import React from "react";
import { Link } from "react-router-dom";

const CarouselCard = ({ article }) => {
  let blogCategory = article?.attributes?.Blog_category?.data?.attributes?.Slug;
  let blogSlug = article?.attributes?.Slug;
  return (
    <Link to={`/blog/articulos/${blogCategory}/${blogSlug}`}>
      <div class="min-w-fit sm:min-w-fit md:min-w-fit lg:min-w-fit xl:w-auto">
        <img
          src={`${process.env.REACT_APP_STRAPI_API_URL}${article?.attributes?.Image?.data?.attributes?.url}`}
          alt={article?.attributes?.Blog_title}
          loading="lazy"
          class="object-cover cursor-pointer h-48 w-72 xxlg:w-full rounded-md"
        />
        <div class="w-72 font-semibold my-4 text-lg leading-6">
          {article?.attributes?.Blog_title}
        </div>
        <div class="w-fit font-medium px-5 py-1 mt-2 bg-[#f4f7fa] text-[#4158dd] rounded-full">
          {article?.attributes?.Blog_category?.data?.attributes?.Title}
        </div>
      </div>
    </Link>
  );
};

export default CarouselCard;
