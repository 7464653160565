import React, { useEffect, useState } from "react";
import Loader from "../components/common/Loader";
import "../styles/BlogNew.css";
import CarouselSection from "../components/blog-new/carousel/CarouselSection";
import Hero from "../components/blog-new/Hero";
import CategoryCarousel from "../components/blog-new/CategoryCarousel";
import BlogCategoriesSection from "../components/blog-new/blog-categories/BlogCategoriesSection";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Helmet } from 'react-helmet';

const BlogHome = () => {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [blogPageContent, setBlogPageContent] = useState(null);

  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState(
    "React, JavaScript, Web Development, Keywords"
  );
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL, setCanonicalURL] = useState([]);
  const [ogLocale, setOgLocale] = useState([]);
  const [ogType, setOgType] = useState([]);
  const [ogTitle, setOgTitle] = useState([]);
  const [ogDescription, setOgDescription] = useState([]);
  const [ogurl, setOgurl] = useState([]);
  const [ogSiteName, setOgSiteName] = useState([]);

  const [articlePublisher, setArticlePublisher] = useState([]);
  const [articleModifiedTime, setArticleModifiedTime] = useState([]);
  const [ogImage, setOgImage] = useState([]);
  const [ogImageWidth, setOgImageWidth] = useState([]);
  const [ogImageHeight, setOgImageHeight] = useState([]);
  const [ogImageType, setOgImageType] = useState([]);

  const fetchBlog = async () => {
    setLoading(true);
    const responseBlog = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?populate=Image,Blog_category&sort=createdAt:Desc`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const blogData = await responseBlog.json();
    setBlogData(blogData.data);

    const responseBlogCategories = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-categories?fields[0]=Title&fields[1]=Slug&[populate][Image][fields][0]=url`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const blogCategories = await responseBlogCategories.json();
    setBlogCategories(blogCategories.data);

    const fetchPageContent = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-home?populate=SEO`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const pageData = await fetchPageContent.json();

    setPageTitle(pageData?.data?.attributes?.SEO?.metaTitle);
    setPageKeywords(pageData?.data?.attributes?.SEO?.keywords);
    setPageDescription(pageData?.data?.attributes?.SEO?.metaDescription);

    setCanonicalURL(pageData?.data?.attributes?.SEO?.canonicalURL);
    setOgLocale(pageData?.data?.attributes?.SEO?.ogLocale);
    setOgType(pageData?.data?.attributes?.SEO?.ogType);
    setOgTitle(pageData?.data?.attributes?.SEO?.ogTitle);
    setOgDescription(pageData?.data?.attributes?.SEO?.ogDescription);
    setOgurl(pageData?.data?.attributes?.SEO?.ogurl);
    setOgSiteName(pageData?.data?.attributes?.SEO?.ogSiteName);

    setArticlePublisher(pageData?.data?.attributes?.SEO?.articlePublisher);
    setArticleModifiedTime(pageData?.data?.attributes?.SEO?.articleModifiedTime);
    setOgImage(pageData?.data?.attributes?.SEO?.ogImage);
    setOgImageWidth(pageData?.data?.attributes?.SEO?.ogImageWidth);
    setOgImageHeight(pageData?.data?.attributes?.SEO?.ogImageHeight);
    setOgImageType(pageData?.data?.attributes?.SEO?.ogImageType);

    setBlogPageContent(pageData?.data?.attributes);

    setLoading(false);
  };

  // console.log(blogCategories);

  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={pageKeywords} />
        <meta name="description" content={pageDescription} />

        {canonicalURL ? <link rel="canonical" href={canonicalURL} /> : void 0}

        {ogLocale ? <meta property="og:locale" content={ogLocale} /> : void 0}

        {ogType ? <meta property="og:type" content={ogType} /> : void 0}

        {ogTitle ? <meta property="og:title" content={ogTitle} /> : void 0}

        {ogDescription ? (
          <meta property="og:description" content={ogDescription} />
        ) : (
          void 0
        )}

        {ogurl ? <meta property="og:url" content={ogurl} /> : void 0}

        {ogSiteName ? (
          <meta property="og:site_name" content={ogSiteName} />
        ) : (
          void 0
        )}

        {ogType ? <meta property="og:type" content={ogType} /> : void 0}

        {articlePublisher ? (
          <meta property="article:publisher" content={articlePublisher} />
        ) : (
          void 0
        )}

        {articleModifiedTime ? (
          <meta
            property="article:modified_time"
            content={articleModifiedTime}
          />
        ) : (
          void 0
        )}

        {ogImage ? <meta property="og:image" content={ogImage} /> : void 0}

        {ogImageWidth ? (
          <meta property="og:image:width" content={ogImageWidth} />
        ) : (
          void 0
        )}

        {ogImageHeight ? (
          <meta property="og:image:height" content={ogImageHeight} />
        ) : (
          void 0
        )}

        {ogImageType ? (
          <meta property="og:image:type" content={ogImageType} />
        ) : (
          void 0
        )}

      </Helmet>

      {!loading ? (
        <>
          <Hero content={blogPageContent} />
          {/* <CategoryCarousel data={blogData} /> */}
          <div className="px-6 sm:px-16 lg:px-32 xlmd:px-52  pt-10 sm:pb-10 pb-2 subscribe-newsletter flex items-center justify-between rounded-lg flex-col mdcustom:flex-row">
            <div className="mr-0 mdcustom:mb-0 mb-9 text-[#495057] text-lg flex-1">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {blogPageContent?.Content}
              </ReactMarkdown>
            </div>
            {/* <div className="bg-newsletter bg-cover bg-center w-full mdcustom:w-[42.7%] pt-32 mdcustom:px-12 px-6 mdcustom:pb-20 pb-14 text-center rounded-md">
              <div className="mdcustom:text-3xl text-[28px] font-bold text-white leading-snug">
                Suscríbase a nuestro Newsletter
              </div>
              <p className="text-base text-[#E5E5E5] pt-4 pb-8">
                Ingrese el correo electrónico para <br />
                suscribirse
              </p>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Correo electrónico"
                  className="py-4 pl-8 pr-4 text-black rounded-lg  w-full text-lg bg-[#f3f3f3]"
                  value=""
                />
                <button
                  className="text-black py-2 px-4 rounded-r absolute right-0 top-0 bottom-0"
                  aria-label="Mail submit"
                >
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ArrowForwardIcon"
                  >
                    <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                  </svg>
                </button>
              </div>
            </div> */}
          </div>

          <BlogCategoriesSection data={blogCategories} />

          <CarouselSection data={blogData} />

          <div className="px-6 sm:px-16 lg:px-32 xlmd:px-52 py-12">
            <div className="bg-advicebanner bg-cover bg-center pt-28 pb-[133px] sm:py-14 px-3 sm:px-5 text-center rounded-md">
              <div className="mdcustom:block hidden text-[32px] font-bold text-white leading-10">
                ¿Necesita asesoría sobre algún servicio o <br />
                producto?{" "}
              </div>
              <div className="mdcustom:hidden block text-2xl tablet:text-[32px] font-bold text-white pb-2">
                ¿Necesita asesoría sobre algún servicio o producto?{" "}
              </div>
              <a
                href="https://mejorempresa.nablasol.net/categorias"
                className="inline-block text-[#00003A] text-base font-bold bg-white py-3.5 px-4 rounded-md my-7"
              >
                Obtenga ayuda aquí
              </a>
              <div className="text-[22px] tablet:text-2xl font-semibold text-white">
                ¡Estamos para ayudarle!
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

export default BlogHome;
