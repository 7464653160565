import React, { useState, useEffect } from "react";
import Loader from "../components/common/Loader";
import HeroSection from "../components/category/hero-section/HeroSection"
import CategorySection from "../components/category/category-section/CategorySection";
import {Helmet} from 'react-helmet';

const NewCategory = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([])
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState(
    "React, JavaScript, Web Development, Keywords"
  );
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL , setCanonicalURL] = useState([]);
  const [ogLocale , setOgLocale] = useState([]);
  const [ogType , setOgType] = useState([]);
  const [ogTitle , setOgTitle] = useState([]);
  const [ogDescription , setOgDescription] = useState([]);
  const [ogurl , setOgurl] = useState([]);
  const [ogSiteName , setOgSiteName] = useState([]);

  const [articlePublisher , setArticlePublisher] = useState([]);
  const [articleModifiedTime , setArticleModifiedTime] = useState([]);
  const [ogImage , setOgImage] = useState([]);
  const [ogImageWidth , setOgImageWidth] = useState([]);
  const [ogImageHeight , setOgImageHeight] = useState([]);
  const [ogImageType , setOgImageType] = useState([]);

  const apiCall = async () => {
    setLoading(true);
    //API call for company category
    const categoryApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/category?populate[Category_lists][populate]=Image&populate[Category_lists][populate]=Category_name.Category_name&populate=seo`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const categoryData = await categoryApiResponse.json();
    setCategory(categoryData?.data?.attributes);

    setPageTitle(categoryData?.data?.attributes?.seo?.metaTitle)
    setPageKeywords(categoryData?.data?.attributes?.seo?.keywords)
    setPageDescription(categoryData?.data?.attributes?.seo?.metaDescription)

    setCanonicalURL(categoryData?.data?.attributes?.seo?.canonicalURL)
    setOgLocale(categoryData?.data?.attributes?.seo?.ogLocale)
    setOgType(categoryData?.data?.attributes?.seo?.ogType)
    setOgTitle(categoryData?.data?.attributes?.seo?.ogTitle)
    setOgDescription(categoryData?.data?.attributes?.seo?.ogDescription)
    setOgurl(categoryData?.data?.attributes?.seo?.ogurl)
    setOgSiteName(categoryData?.data?.attributes?.seo?.ogSiteName)

    setArticlePublisher(categoryData?.data?.attributes?.seo?.articlePublisher)
    setArticleModifiedTime(categoryData?.data?.attributes?.seo?.articleModifiedTime)
    setOgImage(categoryData?.data?.attributes?.seo?.ogImage)
    setOgImageWidth(categoryData?.data?.attributes?.seo?.ogImageWidth)
    setOgImageHeight(categoryData?.data?.attributes?.seo?.ogImageHeight)
    setOgImageType(categoryData?.data?.attributes?.seo?.ogImageType)

    setLoading(false);
  }

  useEffect(() => {
    apiCall();
  }, []);

  
  return (
    <div className="">
    <Helmet>
          <title>{pageTitle}</title>
          <meta name="keywords" content={pageKeywords} />
          <meta name="description" content={pageDescription} />
          {canonicalURL ? (
            <link rel="canonical" href={canonicalURL} />
            ) : (  void 0)
          }  
          
          {ogLocale ? (
            <meta property="og:locale" content={ogLocale} />
            ) : (  void 0)
          }

          {ogType ? (
            <meta property="og:type" content={ogType} />
            ) : (  void 0)
          }

          {ogTitle ? (
            <meta property="og:title" content={ogTitle} />
            ) : (  void 0)
          }
          
          {ogDescription ? (
            <meta property="og:description" content={ogDescription} />
            ) : (  void 0)
          }

          {ogurl ? (
            <meta property="og:url" content={ogurl} />
            ) : (  void 0)
          }
          
          {ogSiteName ? (
            <meta property="og:site_name" content={ogSiteName} />
            ) : (  void 0)
          }      

          {ogType ? (
            <meta property="og:type" content={ogType} />
            ) : (  void 0)
          }           

          {articlePublisher ? (
            <meta property="article:publisher" content={articlePublisher} />
            ) : (  void 0)
          }
          
          {articleModifiedTime ? (
            <meta property="article:modified_time" content={articleModifiedTime} />
            ) : (  void 0)
          }

          {ogImage ? (
            <meta property="og:image" content={ogImage} />
            ) : (  void 0)
          }
          
          {ogImageWidth ? (
            <meta property="og:image:width" content={ogImageWidth} />
            ) : (  void 0)
          }

          {ogImageHeight ? (
            <meta property="og:image:height" content={ogImageHeight} />
            ) : (  void 0)
          }

          {ogImageType ? (
            <meta property="og:image:type" content={ogImageType} />
            ) : (  void 0)
          }
          
     </Helmet>
    {!loading ? (
      <>
        <HeroSection category={category}/>
        <CategorySection category={category}/>
      </>
    ) : (
      <div className="flex justify-center align-middle items-center min-h-screen">
        <Loader />
      </div>
    )}
  </div>
  );
};

export default NewCategory;
