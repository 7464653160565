export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.indexOf(cookieName + "=") === 0) {
      try {
        const jsonData = cookie.substring(
          cookieName.length + 1,
          cookie.indexOf("expires=")
        );
        return JSON.parse(decodeURIComponent(jsonData));
      } catch (error) {
        console.error("Error parsing cookie data:", error);
        return null;
      }
    }
  }

  return null; // Return null if the cookie is not found
};
