import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const PartnerCarouselCard = ({ data }) => {
  return (
    <div className="reason bg-white rounded-t-lg overflow-hidden">
      <div className="img-box h-56 aspect-square w-full">
        <img
          src={`${process.env.REACT_APP_STRAPI_API_URL}${data?.attributes?.Image?.data?.attributes?.url}`}
          alt="poster"
          className="h-full w-full object-cover "
        />
      </div>
      <div className="content px-7 py-8">
        <h3 className="text-lg text-[#00003A] text-lg font-bold">
          {data?.attributes?.Title}
        </h3>
        <p className="text-base text-[#00003ab0] font-normal mt-3 ">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {data?.attributes?.Description}
          </ReactMarkdown>
        </p>
      </div>
    </div>
  );
};

export default PartnerCarouselCard;
