import React , { useEffect , useState } from "react";
import Loader from "../common/Loader";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {Helmet} from 'react-helmet';
import "../../styles/PrivacyPolicy.css";


const TermsCondition = () => {
    const [loading, setLoading] = useState(false);
    const [pageData , setPageData] = useState([])
    const [pageTitle, setPageTitle] = useState("Mejor Empresa");
    const [pageKeywords, setPageKeywords] = useState("React, JavaScript, Web Development, Keywords")
    const [pageDescription, setPageDescription] = useState(
      "Mejor Empresa site is created using React JS and Strapi"
    );

    const [canonicalURL , setCanonicalURL] = useState([]);
    const [ogLocale , setOgLocale] = useState([]);
    const [ogType , setOgType] = useState([]);
    const [ogTitle , setOgTitle] = useState([]);
    const [ogDescription , setOgDescription] = useState([]);
    const [ogurl , setOgurl] = useState([]);
    const [ogSiteName , setOgSiteName] = useState([]);

    const [articlePublisher , setArticlePublisher] = useState([]);
    const [articleModifiedTime , setArticleModifiedTime] = useState([]);
    const [ogImage , setOgImage] = useState([]);
    const [ogImageWidth , setOgImageWidth] = useState([]);
    const [ogImageHeight , setOgImageHeight] = useState([]);
    const [ogImageType , setOgImageType] = useState([]);
    
    const apiCall = async () => {
        setLoading(true);
    
        const PageApiResponse = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/api/pages?populate=seo`,
          {
            method: "GET",
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          }
        );
        const dataOne = await PageApiResponse.json();
        
        setPageTitle(dataOne?.data?.[0]?.attributes?.seo?.metaTitle)
        setPageKeywords(dataOne?.data?.[0]?.attributes?.seo?.keywords)
        setPageDescription(dataOne?.data?.[0]?.attributes?.seo?.metaDescription)

        setCanonicalURL(dataOne?.data?.[0]?.attributes?.seo?.canonicalURL)
        setOgLocale(dataOne?.data?.[0]?.attributes?.seo?.ogLocale)
        setOgType(dataOne?.data?.[0]?.attributes?.seo?.ogType)
        setOgTitle(dataOne?.data?.[0]?.attributes?.seo?.ogTitle)
        setOgDescription(dataOne?.data?.[0]?.attributes?.seo?.ogDescription)
        setOgurl(dataOne?.data?.[0]?.attributes?.seo?.ogurl)
        setOgSiteName(dataOne?.data?.[0]?.attributes?.seo?.ogSiteName)

        setArticlePublisher(dataOne?.data?.[0]?.attributes?.seo?.articlePublisher)
        setArticleModifiedTime(dataOne?.data?.[0]?.attributes?.seo?.articleModifiedTime)
        setOgImage(dataOne?.data?.[0]?.attributes?.seo?.ogImage)
        setOgImageWidth(dataOne?.data?.[0]?.attributes?.seo?.ogImageWidth)
        setOgImageHeight(dataOne?.data?.[0]?.attributes?.seo?.ogImageHeight)
        setOgImageType(dataOne?.data?.[0]?.attributes?.seo?.ogImageType)
        
        setPageData(dataOne?.data?.[0]?.attributes);
        setLoading(false);
      };
    
      useEffect(() => {
        apiCall();
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="keywords" content={pageKeywords} />
            <meta name="description" content={pageDescription} />
            {canonicalURL ? (
              <link rel="canonical" href={canonicalURL} />
              ) : (  void 0)
            }  
            
            {ogLocale ? (
              <meta property="og:locale" content={ogLocale} />
              ) : (  void 0)
            }

            {ogType ? (
              <meta property="og:type" content={ogType} />
              ) : (  void 0)
            }

            {ogTitle ? (
              <meta property="og:title" content={ogTitle} />
              ) : (  void 0)
            }
            
            {ogDescription ? (
              <meta property="og:description" content={ogDescription} />
              ) : (  void 0)
            }

            {ogurl ? (
              <meta property="og:url" content={ogurl} />
              ) : (  void 0)
            }
            
            {ogSiteName ? (
              <meta property="og:site_name" content={ogSiteName} />
              ) : (  void 0)
            }      

            {ogType ? (
              <meta property="og:type" content={ogType} />
              ) : (  void 0)
            }           

            {articlePublisher ? (
              <meta property="article:publisher" content={articlePublisher} />
              ) : (  void 0)
            }
            
            {articleModifiedTime ? (
              <meta property="article:modified_time" content={articleModifiedTime} />
              ) : (  void 0)
            }

            {ogImage ? (
              <meta property="og:image" content={ogImage} />
              ) : (  void 0)
            }
            
            {ogImageWidth ? (
              <meta property="og:image:width" content={ogImageWidth} />
              ) : (  void 0)
            }

            {ogImageHeight ? (
              <meta property="og:image:height" content={ogImageHeight} />
              ) : (  void 0)
            }

            {ogImageType ? (
              <meta property="og:image:type" content={ogImageType} />
              ) : (  void 0)
            }
            
           </Helmet>
            {!loading ? (
                <>
                  <div className="bg-ranking-page bg-cover flex flex-col justify-center items-center py-20">
                    <div className="flex flex-col align-middle w-auto px-3 sm:px-0 sm:w-4/5 md:w-3/4 lg:w-2/4 text-white text-center">
                      <div className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-10 tracking-wide">
                        {pageData.Title}
                      </div>
                    </div>
                  </div>
                <div className="px-4 sm:px-12 md:px-32 py-10">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageData.Content}</ReactMarkdown>
                </div>
              </>
                ) : (
                <div className="flex justify-center align-middle items-center min-h-screen">
                <Loader />
                </div>
            )}
        </>
    )
}

export default TermsCondition;