import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/common/Loader";
import HeroSection from "../components/blog/hero-section/HeroSection";
import BlogSection from "../components/blog/blog-section/BlogSection";
import ArticleSection from "../components/blog/article-section/ArticleSection";
import NotFound from './NotFound';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [blogPageData, setBlogPageData] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [blogFAQData, setBlogFAQData] = useState([]);
  const [articleSectionData, setArticleSectionData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState("React, JavaScript, Web Development, Keywords")
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL, setCanonicalURL] = useState([]);
  const [ogLocale, setOgLocale] = useState([]);
  const [ogType, setOgType] = useState([]);
  const [ogTitle, setOgTitle] = useState([]);
  const [ogDescription, setOgDescription] = useState([]);
  const [ogurl, setOgurl] = useState([]);
  const [ogSiteName, setOgSiteName] = useState([]);

  const [articlePublisher, setArticlePublisher] = useState([]);
  const [articleModifiedTime, setArticleModifiedTime] = useState([]);
  const [ogImage, setOgImage] = useState([]);
  const [ogImageWidth, setOgImageWidth] = useState([]);
  const [ogImageHeight, setOgImageHeight] = useState([]);
  const [ogImageType, setOgImageType] = useState([]);
  const [structuredData, setStructuredData] = useState([]);

  let { blog, category } = useParams();
  let categoryName = category;
  let blogSlug = blog;
  const apiCall = async () => {
    setLoading(true);
    const blogSectionApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?populate=Author.Image,Blog_topics,seo,Blog_category,Blog_faq_groups&filters[Slug][$eqi]=${blogSlug}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const blogData = await blogSectionApiResponse.json();
    setBlogPageData(blogData?.data[0]?.attributes);

    const responseBlogCategories = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-categories?fields[0]=Title&fields[1]=Slug&[populate][Image][fields][0]=url`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const blogCategories = await responseBlogCategories.json();
    setBlogCategories(blogCategories.data);

    const retrievedValue = getWithExpiry('pageViewed' + blogData?.data?.[0]?.id);

    if (blogData?.data?.[0]?.attributes && !retrievedValue) {
      let hitViews = +(blogData?.data?.[0]?.attributes?.views) + 1;
      const data = {
        data: {
          views: hitViews,
        },
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages/` + blogData?.data?.[0]?.id,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
            body: JSON.stringify(data),
          }
        );
        await response.json();
        setWithExpiry('pageViewed' + blogData?.data?.[0]?.id, 'true', 86400000);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (blogData?.data?.[0]?.attributes?.Blog_faq_groups) {
      let blogFAQGroups = [];
      for (let i = 0; i < blogData?.data?.[0]?.attributes?.Blog_faq_groups.data.length; i++) {
        const blogFAQGroupSectionApiResponse = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-fa-qs?populate=*&filters[Blog_faq_groups][id][$eqi]=${blogData?.data?.[0]?.attributes?.Blog_faq_groups.data?.[i]?.id}`,
          {
            method: "GET",
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          }
        );
        blogFAQGroups.push(await blogFAQGroupSectionApiResponse.json());
      }
      setBlogFAQData(blogFAQGroups);
    }

    setPageTitle(blogData?.data?.[0]?.attributes?.seo?.metaTitle)
    setPageKeywords(blogData?.data?.[0]?.attributes?.seo?.keywords)
    setPageDescription(blogData?.data?.[0]?.attributes?.seo?.metaDescription)

    setCanonicalURL(blogData?.data?.[0]?.attributes?.seo?.canonicalURL)
    setOgLocale(blogData?.data?.[0]?.attributes?.seo?.ogLocale)
    setOgType(blogData?.data?.[0]?.attributes?.seo?.ogType)
    setOgTitle(blogData?.data?.[0]?.attributes?.seo?.ogTitle)
    setOgDescription(blogData?.data?.[0]?.attributes?.seo?.ogDescription)
    setOgurl(blogData?.data?.[0]?.attributes?.seo?.ogurl)
    setOgSiteName(blogData?.data?.[0]?.attributes?.seo?.ogSiteName)

    setArticlePublisher(blogData?.data?.[0]?.attributes?.seo?.articlePublisher)
    setArticleModifiedTime(blogData?.data?.[0]?.attributes?.seo?.articleModifiedTime)
    setOgImage(blogData?.data?.[0]?.attributes?.seo?.ogImage)
    setOgImageWidth(blogData?.data?.[0]?.attributes?.seo?.ogImageWidth)
    setOgImageHeight(blogData?.data?.[0]?.attributes?.seo?.ogImageHeight)
    setOgImageType(blogData?.data?.[0]?.attributes?.seo?.ogImageType)
    setStructuredData(blogData?.data?.[0]?.attributes?.seo?.structuredData)

    if (blogData?.data?.[0]?.id) {
      const blogArticleSectionApiResponse = await fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?populate=Image,Blog_category&filters[Blog_category][Slug][$eqi]=${categoryName}&filters[id][$ne]=${blogData?.data?.[0]?.id}&sort=createdAt:Desc`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
        }
      );
      const blogArticles = await blogArticleSectionApiResponse.json();

      setArticleSectionData(blogArticles?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
    window.scrollTo(0, 0);
  }, [blog]);


  function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // Set the value and expiration time in milliseconds
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Check if the item is expired
    if (now.getTime() > item.expiry) {
      // Remove the item from localStorage if it's expired
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={pageKeywords} />
        <meta name="description" content={pageDescription} />
        {canonicalURL ? (
          <link rel="canonical" href={canonicalURL} />
        ) : (void 0)
        }

        {ogLocale ? (
          <meta property="og:locale" content={ogLocale} />
        ) : (void 0)
        }

        {ogType ? (
          <meta property="og:type" content={ogType} />
        ) : (void 0)
        }

        {ogTitle ? (
          <meta property="og:title" content={ogTitle} />
        ) : (void 0)
        }

        {ogDescription ? (
          <meta property="og:description" content={ogDescription} />
        ) : (void 0)
        }

        {ogurl ? (
          <meta property="og:url" content={ogurl} />
        ) : (void 0)
        }

        {ogSiteName ? (
          <meta property="og:site_name" content={ogSiteName} />
        ) : (void 0)
        }

        {ogType ? (
          <meta property="og:type" content={ogType} />
        ) : (void 0)
        }

        {articlePublisher ? (
          <meta property="article:publisher" content={articlePublisher} />
        ) : (void 0)
        }

        {articleModifiedTime ? (
          <meta property="article:modified_time" content={articleModifiedTime} />
        ) : (void 0)
        }

        {ogImage ? (
          <meta property="og:image" content={ogImage} />
        ) : (void 0)
        }

        {ogImageWidth ? (
          <meta property="og:image:width" content={ogImageWidth} />
        ) : (void 0)
        }

        {ogImageHeight ? (
          <meta property="og:image:height" content={ogImageHeight} />
        ) : (void 0)
        }

        {ogImageType ? (
          <meta property="og:image:type" content={ogImageType} />
        ) : (void 0)
        }

        {structuredData ? (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        ) : (void 0)
        }

      </Helmet>
      {!loading ? (
        <>
          {blogPageData ? (
            <>
              <HeroSection data={blogPageData} />
              <BlogSection blogContent={blogPageData?.Blog_content}
                blogAuthor={blogPageData?.Author?.data?.attributes}
                blogTopics={blogCategories} blogFAQs={blogFAQData}
              />
              <ArticleSection data={articleSectionData} category={categoryName} />
            </>
          ) : (
            <NotFound />
          )
          }
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Blog;




