import React from "react";
import TopicSelectionSection from "../topic-selection-section/TopicSelectionSection";
import BlogContentSection from "../blog-content-section/BlogContentSection"

const BlogSection = ({ blogContent , blogAuthor , blogTopics , blogFAQs}) => {
 
  return (
    <div className="flex flex-col gap-5 mx-8 my-14 sm:mx-14 md:mx-20 base:mr-44 lg:mr-56 mdcustom:flex-row md:gap-20 base:mx-28 lg:ml-32">
      <TopicSelectionSection blogTopics={blogTopics} />
      <BlogContentSection blogContent={blogContent} blogAuthor={blogAuthor} blogFAQs={blogFAQs} />
    </div>
  );
};

export default BlogSection;
