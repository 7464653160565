import React from "react";
import Carousel from "./Carousel";

const CarouselSection = ({ data }) => {
  return (
    <>
      <Carousel data={data} />
    </>
  );
};

export default CarouselSection;
