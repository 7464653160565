import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const HeroSection = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const formatDescription = () => {
    const desc =
      windowWidth >= 992
        ? data?.Title?.replace("siguiente", "siguiente <br>")
        : data?.Title?.replace("siguiente <br>", "iguiente");
    const descElement = document.querySelector(".partner__hero-title");
    descElement.innerHTML = desc;
  };

  useEffect(() => {
    formatDescription();
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="hero h-[550px] bg-partner text-white mdcustom:h-[550px] ">
      <div className="wrapper flex flex-col justify-center  gap-8 h-full mx-6 tablet:mx-16 lg:mx-32 pt-20 mdcustom:mr-0 ">
        <h1 className="text-2xl sm:text-4xl mdcustom:text-[42px] leading-normal  font-semibold partner__hero-title tablet:leading-[42px] ">
          {data?.Title}
        </h1>
        <p className="text-base sm:text-lg mdcustom:text-xl font-normal text-[#E5E5E5] w-full mdcustom:w-3/5">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {data?.Description}
          </ReactMarkdown>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
