import ParntershipFaqCard from "./PartnershipFaqCard";

const ParntershipFAQ = ({ question }) => {
  return (
    <div className="gap-14">
      <div
        id="faq"
        className="flex justify-center items-center mt-16 text-xl md:text-3xl font-semibold text-black px-10"
      >
        Preguntas frecuentes sobre asociarse con Mejor Empresa
      </div>
      <div className="mt-10 gap-8 mx-10 mb-12 tablet:mx-20 mdcustom:mx-30 base:mx-60">
        {question?.data?.map((item, index) => (
          <div className="mb-4" key={index}>
            <ParntershipFaqCard question={item} key={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParntershipFAQ;
