// NotFound.js
import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const NotFound = () => {
  return (
    <>
    <div className={`flex flex-col justify-center sm:text-start w-full pl-10 pr-4 md:px-20 lg:px-32 py-24 text-white bg-blog-page bg-cover pb-8 pt-52 test}`}>  
    </div>
    <NotFoundContainer>
      <div className="pb-28 pt-20"> 
        <h1>404 - Not Found</h1>
        <p>Sorry, we can't find the page you are looking for.</p>
      </div>   
    </NotFoundContainer>
    </>
  );
};

export default NotFound;
