import React, { useState, useEffect } from "react";
import HeroSection from "../components/company/hero-section/HeroSection";
import Loader from "../components/common/Loader";
import CompanySection from "../components/company/company-section/ComapnySection";
import NotFound from './NotFound';
import { useParams } from "react-router-dom";
import {Helmet} from 'react-helmet';

const Company = () => {
  const [loading, setLoading] = useState(false);
  const [companyPageData, setCompanyPageData] = useState([]);
  const [companyReviewData, setCompanyReviewData] = useState([]);
  const [companyFaqData, setCompanyFaqData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState("React, JavaScript, Web Development, Keywords")
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL , setCanonicalURL] = useState([]);
  const [ogLocale , setOgLocale] = useState([]);
  const [ogType , setOgType] = useState([]);
  const [ogTitle , setOgTitle] = useState([]);
  const [ogDescription , setOgDescription] = useState([]);
  const [ogurl , setOgurl] = useState([]);
  const [ogSiteName , setOgSiteName] = useState([]);

  const [articlePublisher , setArticlePublisher] = useState([]);
  const [articleModifiedTime , setArticleModifiedTime] = useState([]);
  const [ogImage , setOgImage] = useState([]);
  const [ogImageWidth , setOgImageWidth] = useState([]);
  const [ogImageHeight , setOgImageHeight] = useState([]);
  const [ogImageType , setOgImageType] = useState([]);
  const [structuredData , setStructuredData] = useState([]);

  let { company } = useParams();
  
  const apiCall = async () => {
    setLoading(true);

    const companyPageApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/company-lists?populate[Hero_Section][populate]=Author_name&populate=logo&populate[seo][populate]=*&filters[Slug][$eqi]=${company}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const dataOne = await companyPageApiResponse.json();
   
    setCompanyPageData(dataOne?.data?.[0]?.attributes);
    setPageTitle(dataOne?.data?.[0]?.attributes?.seo?.metaTitle)
    setPageKeywords(dataOne?.data?.[0]?.attributes?.seo?.keywords)
    setPageDescription(dataOne?.data?.[0]?.attributes?.seo?.metaDescription)
    
    setCanonicalURL(dataOne?.data?.[0]?.attributes?.seo?.canonicalURL)
    setOgLocale(dataOne?.data?.[0]?.attributes?.seo?.ogLocale)
    setOgType(dataOne?.data?.[0]?.attributes?.seo?.ogType)
    setOgTitle(dataOne?.data?.[0]?.attributes?.seo?.ogTitle)
    setOgDescription(dataOne?.data?.[0]?.attributes?.seo?.ogDescription)
    setOgurl(dataOne?.data?.[0]?.attributes?.seo?.ogurl)
    setOgSiteName(dataOne?.data?.[0]?.attributes?.seo?.ogSiteName)

    setArticlePublisher(dataOne?.data?.[0]?.attributes?.seo?.articlePublisher)
    setArticleModifiedTime(dataOne?.data?.[0]?.attributes?.seo?.articleModifiedTime)
    setOgImage(dataOne?.data?.[0]?.attributes?.seo?.ogImage)
    setOgImageWidth(dataOne?.data?.[0]?.attributes?.seo?.ogImageWidth)
    setOgImageHeight(dataOne?.data?.[0]?.attributes?.seo?.ogImageHeight)
    setOgImageType(dataOne?.data?.[0]?.attributes?.seo?.ogImageType)
    setStructuredData(dataOne?.data?.[0]?.attributes?.seo?.structuredData)

    const companyFaqApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/company-faqs?populate=*&filters[Company_Name][Slug][$eqi]=${company}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const dataFaq = await companyFaqApiResponse.json();
    setCompanyFaqData(dataFaq)

    const companyReviewApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/company-reviews?populate=*&filters[Company_Category][Slug][$eqi]=${company}&sort=Rating:desc`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const dataTwo = await companyReviewApiResponse.json();
    setCompanyReviewData(dataTwo?.data);
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Helmet>
          <title>{pageTitle}</title>
          <meta name="keywords" content={pageKeywords} />
          <meta name="description" content={pageDescription} />
          {canonicalURL ? (
            <link rel="canonical" href={canonicalURL} />
            ) : (  void 0)
          }  
          
          {ogLocale ? (
            <meta property="og:locale" content={ogLocale} />
            ) : (  void 0)
          }

          {ogType ? (
            <meta property="og:type" content={ogType} />
            ) : (  void 0)
          }

          {ogTitle ? (
            <meta property="og:title" content={ogTitle} />
            ) : (  void 0)
          }
          
          {ogDescription ? (
            <meta property="og:description" content={ogDescription} />
            ) : (  void 0)
          }

          {ogurl ? (
            <meta property="og:url" content={ogurl} />
            ) : (  void 0)
          }
          
          {ogSiteName ? (
            <meta property="og:site_name" content={ogSiteName} />
            ) : (  void 0)
          }      

          {ogType ? (
            <meta property="og:type" content={ogType} />
            ) : (  void 0)
          }           

          {articlePublisher ? (
            <meta property="article:publisher" content={articlePublisher} />
            ) : (  void 0)
          }
          
          {articleModifiedTime ? (
            <meta property="article:modified_time" content={articleModifiedTime} />
            ) : (  void 0)
          }

          {ogImage ? (
            <meta property="og:image" content={ogImage} />
            ) : (  void 0)
          }
          
          {ogImageWidth ? (
            <meta property="og:image:width" content={ogImageWidth} />
            ) : (  void 0)
          }

          {ogImageHeight ? (
            <meta property="og:image:height" content={ogImageHeight} />
            ) : (  void 0)
          }

          {ogImageType ? (
            <meta property="og:image:type" content={ogImageType} />
            ) : (  void 0)
          }

          {structuredData ? (
            <script type="application/ld+json">
              {JSON.stringify(structuredData)}
            </script>
            ) : (  void 0)
          }

      </Helmet>
      {!loading ? (
        <>
          {companyPageData ? (
            <>
              <HeroSection data={companyPageData} />
              <CompanySection
                companyPageData={companyPageData}
                companyReviewData={companyReviewData}
                companyFaqData={companyFaqData}
              />
            </>
            ) : (
              <NotFound />
            )
          }
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Company;
