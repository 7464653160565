import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../components/common/Loader";
import HeroSection from "../components/partnership/hero-section/HeroSection";
import Content from "../components/partnership/content/Content";
import PartnershipReasons from "../components/partnership/reasons-section/PartnershipReasons";
import ReviewSection from "../components/partnership/reviews-section/ReviewSection";
import PartnershipForm from "../components/partnership/partnership-form/PartnershipForm";
import ParntershipFAQ from "../components/partnership/FAQ/ParntershipFAQ";
import "../styles/Partnership.css";

const PartnerWithUs = () => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState(
    "React, JavaScript, Web Development, Keywords"
  );
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL, setCanonicalURL] = useState([]);
  const [ogLocale, setOgLocale] = useState([]);
  const [ogType, setOgType] = useState([]);
  const [ogTitle, setOgTitle] = useState([]);
  const [ogDescription, setOgDescription] = useState([]);
  const [ogurl, setOgurl] = useState([]);
  const [ogSiteName, setOgSiteName] = useState([]);

  const [articlePublisher, setArticlePublisher] = useState([]);
  const [articleModifiedTime, setArticleModifiedTime] = useState([]);
  const [ogImage, setOgImage] = useState([]);
  const [ogImageWidth, setOgImageWidth] = useState([]);
  const [ogImageHeight, setOgImageHeight] = useState([]);
  const [ogImageType, setOgImageType] = useState([]);
  const [structuredData, setStructuredData] = useState([]);

  const apiCall = async () => {
    setLoading(true);

    const PageApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/partner-with-us?populate=seo,partnership_reasons,partnership_reasons.Image,Partner_page_faqs`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const data = await PageApiResponse.json();

    setPageTitle(data?.data?.attributes?.seo?.metaTitle);
    setPageKeywords(data?.data?.attributes?.seo?.keywords);
    setPageDescription(data?.data?.attributes?.seo?.metaDescription);

    setCanonicalURL(data?.data?.attributes?.seo?.canonicalURL);
    setOgLocale(data?.data?.attributes?.seo?.ogLocale);
    setOgType(data?.data?.attributes?.seo?.ogType);
    setOgTitle(data?.data?.attributes?.seo?.ogTitle);
    setOgDescription(data?.data?.attributes?.seo?.ogDescription);
    setOgurl(data?.data?.attributes?.seo?.ogurl);
    setOgSiteName(data?.data?.attributes?.seo?.ogSiteName);

    setArticlePublisher(data?.data?.attributes?.seo?.articlePublisher);
    setArticleModifiedTime(data?.data?.attributes?.seo?.articleModifiedTime);
    setOgImage(data?.data?.attributes?.seo?.ogImage);
    setOgImageWidth(data?.data?.attributes?.seo?.ogImageWidth);
    setOgImageHeight(data?.data?.attributes?.seo?.ogImageHeight);
    setOgImageType(data?.data?.attributes?.seo?.ogImageType);
    setStructuredData(data?.data?.attributes?.seo?.structuredData);

    setPageData(data?.data?.attributes);
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={pageKeywords} />
        <meta name="description" content={pageDescription} />

        {canonicalURL ? <link rel="canonical" href={canonicalURL} /> : void 0}

        {ogLocale ? <meta property="og:locale" content={ogLocale} /> : void 0}

        {ogType ? <meta property="og:type" content={ogType} /> : void 0}

        {ogTitle ? <meta property="og:title" content={ogTitle} /> : void 0}

        {ogDescription ? (
          <meta property="og:description" content={ogDescription} />
        ) : (
          void 0
        )}

        {ogurl ? <meta property="og:url" content={ogurl} /> : void 0}

        {ogSiteName ? (
          <meta property="og:site_name" content={ogSiteName} />
        ) : (
          void 0
        )}

        {ogType ? <meta property="og:type" content={ogType} /> : void 0}

        {articlePublisher ? (
          <meta property="article:publisher" content={articlePublisher} />
        ) : (
          void 0
        )}

        {articleModifiedTime ? (
          <meta
            property="article:modified_time"
            content={articleModifiedTime}
          />
        ) : (
          void 0
        )}

        {ogImage ? <meta property="og:image" content={ogImage} /> : void 0}

        {ogImageWidth ? (
          <meta property="og:image:width" content={ogImageWidth} />
        ) : (
          void 0
        )}

        {ogImageHeight ? (
          <meta property="og:image:height" content={ogImageHeight} />
        ) : (
          void 0
        )}

        {ogImageType ? (
          <meta property="og:image:type" content={ogImageType} />
        ) : (
          void 0
        )}

        {structuredData ? (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        ) : (
          void 0
        )}
      </Helmet>
      {!loading ? (
        <>
          <HeroSection data={pageData} />
          <Content data={pageData} />
          <PartnershipReasons data={pageData} />
          {/* <ReviewSection /> */}
          <PartnershipForm data={pageData} />
          <ParntershipFAQ question={pageData?.Partner_page_faqs} />
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

export default PartnerWithUs;
