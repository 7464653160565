import React, { useEffect, useState } from "react";
import Loader from "../components/common/Loader";
import { useParams } from "react-router-dom";
import Hero from "../components/blog-category/Hero";
import CategoryArticles from "../components/blog-category/CategoryArticles";
import NotFound from './NotFound';

const BlogCategory = () => {
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [blogArticles, setBlogArticles] = useState([]);

  let { category } = useParams();

  const apiCall = async () => {
    setLoading(true);
    const categoryApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-categories?populate=SEO&populate=Hero_Section&filters[Slug][$eqi]=${category}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const categoryData = await categoryApiResponse.json();
    setCategoryData(categoryData?.data[0]?.attributes);

    const blogArticlesApiResponse = await fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?fields[0]=Blog_title&fields[1]=slug&[populate][Image][fields][0]=url&sort=createdAt:Desc&filters[Blog_category][Slug][$eqi]=${category}`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
          },
        }
      );
  
      const blogArticles = await blogArticlesApiResponse.json();
      setBlogArticles(blogArticles.data);

      setLoading(false);

  };

  //console.log(categoryData);
  //console.log(blogArticles);

  useEffect(() => {
    apiCall();
    window.scrollTo(0, 0);
  }, [category]);

  return (
    <>
    { !loading ? (
      <>
      {categoryData && blogArticles  ? (
        <>
          <Hero data={categoryData} />
          <CategoryArticles data={blogArticles} category={category}/>
        </>
        ) : (
          <NotFound />
        )
      }  
      </>
    ) : (
      <div className="flex justify-center align-middle items-center min-h-screen">
        <Loader />
      </div>
    )

    }
      
    </>
  );
};

export default BlogCategory;
