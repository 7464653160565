import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyATdrn5PMaCGB7n5DukQhfVHQBV6Viuc98",
  authDomain: "meprod-3923a.firebaseapp.com",
  projectId: "meprod-3923a",
  storageBucket: "meprod-3923a.appspot.com",
  messagingSenderId: "1069546759450",
  appId: "1:1069546759450:web:e0b639eced94461035f8a3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
