import React , { useEffect , useState } from "react";
import Loader from "../../common/Loader";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {Helmet} from 'react-helmet';
import Features from "./Features";
import OurValues from "./OurValues";
import "./About.css"

const AboutUs = () => {
    
    const [loading, setLoading] = useState(false);
    const [pageData , setPageData] = useState([]);
    const [pageTitle, setPageTitle] = useState("Mejor Empresa");
    const [pageKeywords, setPageKeywords] = useState("React, JavaScript, Web Development, Keywords")
    const [pageDescription, setPageDescription] = useState(
      "Mejor Empresa site is created using React JS and Strapi"
    );

    const [canonicalURL , setCanonicalURL] = useState([]);
    const [ogLocale , setOgLocale] = useState([]);
    const [ogType , setOgType] = useState([]);
    const [ogTitle , setOgTitle] = useState([]);
    const [ogDescription , setOgDescription] = useState([]);
    const [ogurl , setOgurl] = useState([]);
    const [ogSiteName , setOgSiteName] = useState([]);

    const [articlePublisher , setArticlePublisher] = useState([]);
    const [articleModifiedTime , setArticleModifiedTime] = useState([]);
    const [ogImage , setOgImage] = useState([]);
    const [ogImageWidth , setOgImageWidth] = useState([]);
    const [ogImageHeight , setOgImageHeight] = useState([]);
    const [ogImageType , setOgImageType] = useState([]);

    const apiCall = async () => {
        setLoading(true);
    
        const PageApiResponse = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/api/about-us?populate=seo,Company_features,Values,Values.Image`,
          {
            method: "GET",
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
          }
        );
  
        const data = await PageApiResponse.json();
         
        setPageTitle(data?.data?.attributes?.seo?.metaTitle)
        setPageKeywords(data?.data?.attributes?.seo?.keywords)
        setPageDescription(data?.data?.attributes?.seo?.metaDescription)

        setCanonicalURL(data?.data?.attributes?.seo?.canonicalURL)
        setOgLocale(data?.data?.attributes?.seo?.ogLocale)
        setOgType(data?.data?.attributes?.seo?.ogType)
        setOgTitle(data?.data?.attributes?.seo?.ogTitle)
        setOgDescription(data?.data?.attributes?.seo?.ogDescription)
        setOgurl(data?.data?.attributes?.seo?.ogurl)
        setOgSiteName(data?.data?.attributes?.seo?.ogSiteName)

        setArticlePublisher(data?.data?.attributes?.seo?.articlePublisher)
        setArticleModifiedTime(data?.data?.attributes?.seo?.articleModifiedTime)
        setOgImage(data?.data?.attributes?.seo?.ogImage)
        setOgImageWidth(data?.data?.attributes?.seo?.ogImageWidth)
        setOgImageHeight(data?.data?.attributes?.seo?.ogImageHeight)
        setOgImageType(data?.data?.attributes?.seo?.ogImageType)
        
        setPageData(data?.data?.attributes);
        setLoading(false);
      };
    
      useEffect(() => {
        apiCall();
      }, []);
    
    return (
        <>
           <Helmet>
            <title>{pageTitle}</title>
            <meta name="keywords" content={pageKeywords} />
            <meta name="description" content={pageDescription} />

            {canonicalURL ? (
              <link rel="canonical" href={canonicalURL} />
              ) : (  void 0)
            }  
            
            {ogLocale ? (
              <meta property="og:locale" content={ogLocale} />
              ) : (  void 0)
            }

            {ogType ? (
              <meta property="og:type" content={ogType} />
              ) : (  void 0)
            }

            {ogTitle ? (
              <meta property="og:title" content={ogTitle} />
              ) : (  void 0)
            }
            
            {ogDescription ? (
              <meta property="og:description" content={ogDescription} />
              ) : (  void 0)
            }

            {ogurl ? (
              <meta property="og:url" content={ogurl} />
              ) : (  void 0)
            }
            
            {ogSiteName ? (
              <meta property="og:site_name" content={ogSiteName} />
              ) : (  void 0)
            }      

            {ogType ? (
              <meta property="og:type" content={ogType} />
              ) : (  void 0)
            }           

            {articlePublisher ? (
              <meta property="article:publisher" content={articlePublisher} />
              ) : (  void 0)
            }
            
            {articleModifiedTime ? (
              <meta property="article:modified_time" content={articleModifiedTime} />
              ) : (  void 0)
            }

            {ogImage ? (
              <meta property="og:image" content={ogImage} />
              ) : (  void 0)
            }
            
            {ogImageWidth ? (
              <meta property="og:image:width" content={ogImageWidth} />
              ) : (  void 0)
            }

            {ogImageHeight ? (
              <meta property="og:image:height" content={ogImageHeight} />
              ) : (  void 0)
            }

            {ogImageType ? (
              <meta property="og:image:type" content={ogImageType} />
              ) : (  void 0)
            }
            
           </Helmet>

            {!loading ? (
                <>
                   <div className="flex flex-col justify-center text-center sm:text-start h-96 w-full px-5 sm:px-6 md:px-20 lg:px-32 text-white  bg-about-page-mobile sm:bg-about-page bg-[52%] bg-cover">
                      <div className="text-38 sm:text-3xl md:text-4xl font-semibold tracking-wide w-full  md:w-3/4 base:w-3/5 lg:w-2/4 mt-20 sm:mt-0">
                        {pageData.Title}
                      </div>
                      <div className="w-full md:w-4/5 base:w-8/12 lg:w-[62%] mt-5 font-thin">
                        {pageData.Description}
                      </div>
                    </div>
                <div className="px-4 sm:px-12 md:px-32 py-10">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageData?.Content}</ReactMarkdown>
                  
                </div>     
                <div className="py-10">
                <Features data={pageData}/> 
                </div>
                <div className="px-4 sm:px-12 md:px-32 py-10">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageData.Mission_and_vision}</ReactMarkdown>
                </div> 
                <OurValues data={pageData}/>
              </>
                ) : (
                <div className="flex justify-center align-middle items-center min-h-screen">
                <Loader />
                </div>
            )}
        </>
    )
}

export default AboutUs;