import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./pages/Home";
import WebsiteComingSoon from "./pages/WebsiteComingSoon";
import Blog from "./pages/Blog";
import "./App.css";
import Company from "./pages/Company";
import TermsCondition from "./components/others/TermsCondition";
import PrivacyPolicy from "./components/others/PrivacyPolicy";
import AboutUs from "./components/others/About us/AboutUs";
import PrivacyPolicyForm from "./components/others/PrivacyPolicyForm";
import { HeaderContextProvider } from "./context/HeaderContext";
import NewRanking from "./pages/NewRanking";
import NewCategory from "./pages/NewCategory";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PartnerWithUs from "./pages/PartnerWithUs";
import BlogHome from "./pages/BlogHome";
import BlogCategory from "./pages/BlogCategory";
import { auth } from "./firebase/firebase";

const Layout = () => {
  const currentLocation = useLocation().pathname;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthState = async () => {
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
          setUser(authUser);
          resolve();
        });

        return () => {
          unsubscribe();
        };
      });
      setLoading(false);
    };

    checkAuthState();
  }, []);

  useEffect(() => {
    const userFromSessionStorage = sessionStorage.getItem("user");

    if (!userFromSessionStorage && !loading && user) {
      navigate("/login");
    }
  }, [user, loading]);

  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
    }
  }, [user, loading, currentLocation, navigate]);

  return (
    <HeaderContextProvider>
      <div className="font-body">
        {currentLocation !== "/login" && <Header />}
        <Outlet />
        {currentLocation !== "/login" && <Footer />}
      </div>
    </HeaderContextProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/categorias/:service",
        element: <NewRanking />,
      },
      {
        path: "/blog/articulos/:category/:blog",
        element: <Blog />,
      },
      {
        path: "/:service/:company",
        element: <Company />,
      },
      {
        path: "/terminos-de-uso",
        element: <TermsCondition />,
      },
      {
        path: "/politica-de-privacidad",
        element: <PrivacyPolicy />,
      },
      {
        path: "/acerca-de-nosotros",
        element: <AboutUs />,
      },
      {
        path: "/formulario-de-politica-de-privacidad",
        element: <PrivacyPolicyForm />,
      },
      {
        path: "/categorias",
        element: <NewCategory />,
      },
      {
        path: "/asociarse",
        element: <PartnerWithUs />,
      },
      {
        path: "/blog",
        element: <BlogHome />,
      },
      {
        path: "/blog/categorias/:category",
        element: <BlogCategory />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <WebsiteComingSoon />,
//   },
// ]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
