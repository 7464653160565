import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
// import "../../../styles/Partnership.css"

const Content = ({ data }) => {
  return (
    <>
      {/* <div className="flex  items-center mx-20 gap-52 py-12">
        <p className="content text-[#495057] text-lg font-normal">
          Asociarse con Mejor Empresa tendrá una serie de <br />
          beneficios significativos para aquellas empresas <br />
          que brindan servicios y proveen productos para <br />
          la comunidad hispana y quieren expandir su <br /> mercado generando
          más oportunidades.
        </p>
        <div className="img-box flex-1">
          <img
            src="https://i.ibb.co/C9Z5qgQ/dc4466fa3a40992d947b77f7b03fea7f.jpg"
            alt="poster"
            className="h-full w-4/5"
          />
        </div>
      </div> */}
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data?.Content}</ReactMarkdown>
    </>
  );
};

export default Content;
