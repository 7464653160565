import React from "react";
import { Link } from "react-router-dom";

const TopicSelectionSection = ({ blogTopics }) => {

  return (
    <div className="hidden base:block flex-1">
      <div className="text-[#495057] w-52 text-lg font-semibold tracking-wide border-b-2 border-[#D8D8D8] pb-4">
        Temas Populares
      </div>
      {blogTopics?.map((topic, index) => (
        <Link
          to={`/blog/categorias/${topic?.attributes?.Slug}`}
          key={index}
        >
          <div key={index} className="w-52 border-b-2 border-[#D8D8D8] py-4">
            {topic?.attributes?.Title}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default TopicSelectionSection;
