import React, { useEffect, useState } from "react";
import HeroSection from "../components/home/hero-section/HeroSection";
import ArticleSection from "../components/home/article-section/ArticleSection";
import CategorySection from "../components/home/category-section/CategorySection";
import AboutSection from "../components/home/about-section/AboutSection";
import ReviewSection from "../components/home/review-section/ReviewSection";
import CarouselSection from "../components/home/carousel-section/CarouselSection";
import Loader from "../components/common/Loader";
import {Helmet} from 'react-helmet'

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [homePageData, setHomePageData] = useState([]);
  const [blogPageData, setBlogPageData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState("React, JavaScript, Web Development, Keywords")
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );
  
  const [canonicalURL , setCanonicalURL] = useState([]);
  const [ogLocale , setOgLocale] = useState([]);
  const [ogType , setOgType] = useState([]);
  const [ogTitle , setOgTitle] = useState([]);
  const [ogDescription , setOgDescription] = useState([]);
  const [ogurl , setOgurl] = useState([]);
  const [ogSiteName , setOgSiteName] = useState([]);

  const [articlePublisher , setArticlePublisher] = useState([]);
  const [articleModifiedTime , setArticleModifiedTime] = useState([]);
  const [ogImage , setOgImage] = useState([]);
  const [ogImageWidth , setOgImageWidth] = useState([]);
  const [ogImageHeight , setOgImageHeight] = useState([]);
  const [ogImageType , setOgImageType] = useState([]);

  const apiCall = async () => {
    setLoading(true);

    const heroSectionQuery =
      "populate[Hero_Section][populate]=Services.Logo";

    const latestArticleSectionQuery =
       "populate[Latest_Article_Section][populate]=Title";

    const categorySectionQuery = "populate[Categories_Section][populate]=*";

    const serviceArticleSectionQuery =
      "populate[Service_Article_Section][populate]=Blog_category.Title";

    const reviewSectionQuery = "populate[Company_Features_Section][populate]=*";

    const aboutUsSectionQuery = "populate[AboutUs_Section][populate]=*";

    const metaSectionQuery = "populate[seo][populate]=*";

    const response = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/home?${heroSectionQuery}&${latestArticleSectionQuery}&${categorySectionQuery}&${serviceArticleSectionQuery}&${reviewSectionQuery}&${aboutUsSectionQuery}&${metaSectionQuery}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
  
    const data = await response.json();
  
    setLoading(false);
    setHomePageData(data?.data?.attributes);
   
    setPageTitle(data?.data?.attributes?.seo?.metaTitle)
    setPageKeywords(data?.data?.attributes?.seo?.keywords)
    setPageDescription(data?.data?.attributes?.seo?.metaDescription)

    setCanonicalURL(data?.data?.attributes?.seo?.canonicalURL)
    setOgLocale(data?.data?.attributes?.seo?.ogLocale)
    setOgType(data?.data?.attributes?.seo?.ogType)
    setOgTitle(data?.data?.attributes?.seo?.ogTitle)
    setOgDescription(data?.data?.attributes?.seo?.ogDescription)
    setOgurl(data?.data?.attributes?.seo?.ogurl)
    setOgSiteName(data?.data?.attributes?.seo?.ogSiteName)

    setArticlePublisher(data?.data?.attributes?.seo?.articlePublisher)
    setArticleModifiedTime(data?.data?.attributes?.seo?.articleModifiedTime)
    setOgImage(data?.data?.attributes?.seo?.ogImage)
    setOgImageWidth(data?.data?.attributes?.seo?.ogImageWidth)
    setOgImageHeight(data?.data?.attributes?.seo?.ogImageHeight)
    setOgImageType(data?.data?.attributes?.seo?.ogImageType)

    const responseBlog = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?populate=Image,Blog_category&sort=createdAt:Desc&pagination[limit]=3`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
  
    const dataBlog = await responseBlog.json();  
    setBlogPageData(dataBlog);
  };

  useEffect(() => {
    apiCall();
  }, []);
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={pageKeywords} />
        <meta name="description" content={pageDescription} />
        
        {canonicalURL ? (
          <link rel="canonical" href={canonicalURL} />
          ) : (  void 0)
        }  
        
        {ogLocale ? (
          <meta property="og:locale" content={ogLocale} />
          ) : (  void 0)
        }

        {ogType ? (
          <meta property="og:type" content={ogType} />
          ) : (  void 0)
        }

        {ogTitle ? (
          <meta property="og:title" content={ogTitle} />
          ) : (  void 0)
        }
        
        {ogDescription ? (
          <meta property="og:description" content={ogDescription} />
          ) : (  void 0)
        }

        {ogurl ? (
          <meta property="og:url" content={ogurl} />
          ) : (  void 0)
        }
        
        {ogSiteName ? (
          <meta property="og:site_name" content={ogSiteName} />
          ) : (  void 0)
        }

        
        {articlePublisher ? (
          <meta property="article:publisher" content={articlePublisher} />
          ) : (  void 0)
        }
        
        {articleModifiedTime ? (
          <meta property="article:modified_time" content={articleModifiedTime} />
          ) : (  void 0)
        }

        {ogImage ? (
          <meta property="og:image" content={ogImage} />
          ) : (  void 0)
        }
        
        {ogImageWidth ? (
          <meta property="og:image:width" content={ogImageWidth} />
          ) : (  void 0)
        }

        {ogImageHeight ? (
          <meta property="og:image:height" content={ogImageHeight} />
          ) : (  void 0)
        }

        {ogImageType ? (
          <meta property="og:image:type" content={ogImageType} />
          ) : (  void 0)
        }

      </Helmet>
      {!loading ? (
        <>
          <HeroSection data={homePageData?.Hero_Section} />
          <ArticleSection articles={blogPageData?.data} title={homePageData?.Latest_Article_Section} />
          <CategorySection data={homePageData?.Categories_Section} />
          <CarouselSection data={homePageData?.Service_Article_Section} />
          <ReviewSection data={homePageData?.Company_Features_Section} />
          <AboutSection data={homePageData?.AboutUs_Section} />
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Home;
