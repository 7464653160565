import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const HeroSection = ({ data }) => {
  return (
    <div className="bg-rankingbgMobile sm:bg-rankingbg bg-[right_-270px_center] sm:bg-center bg-no-repeat bg-[length:270%] sm:bg-cover h-auto min-h-full flex justify-start items-center text-white lg:h-72">
      <div className="title mx-4 mb-8 mt-44 flex flex-col justify-start text-white text-center sm:mt-12 sm:mx-14 sm:mb-14 sm:text-left md:mx-20 base:mx-28 lg:mx-36">
        <h1 className="text-4xl font-semibold">{data?.Category_name}</h1>
        <p className="text-base text-white font-normal mt-4 w-full md:w-3/5">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {data?.Long_description}
          </ReactMarkdown>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
