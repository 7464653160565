import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PartnerCarouselCard from "./PartnerCarouselCard";

const PartnerCarousel = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider className="partner-slider" {...settings}>
      {data?.partnership_reasons?.data?.map((element, index) => (
        <PartnerCarouselCard key={index} data={element} />
      ))}
    </Slider>
  );
};

export default PartnerCarousel;
