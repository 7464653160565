import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ArticleSectionCard = ({ article, category }) => {
  let blogCategory = category;
  let blogSlug = article?.attributes?.Slug;
  return (
    <Link
      to={`/blog/articulos/${blogCategory}/${blogSlug}`}
      className="flex justify-center items-center w-full sm:w-full md:w-full lg:w-full overflow-hidden rounded-md relative before:absolute before:left-0 before:right-0 before:top-0 before:h-full before:w-full before:bg-black before:opacity-40"
    >
      <LazyLoadImage
        src={`${process.env.REACT_APP_STRAPI_API_URL}${article?.attributes?.Image?.data?.attributes?.url}`}
        alt={article?.attributes?.Blog_title}
        className="rounded-lg w-full max-w-full h-auto object-cover xxlg:max-h-auto"
      />
      <div className="absolute w-4/5 bottom-6 left-6 text-base text-white xxlg:text-2xl xxlg:leading-normal">
        {article?.attributes?.Blog_title}
      </div>
      <div className="absolute top-3 text-white bg-[#f4f7fa43] rounded-3xl px-6 py-1 right-6 xxlg:text-2xl">
        {article?.attributes?.Blog_category?.data?.attributes?.Title}
      </div>
    </Link>
  );
};

export default ArticleSectionCard;
