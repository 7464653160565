import React from "react";
import CategoryArticleCard from "./CategoryArticleCard";

const CategoryArticles = ({ data,category }) => {
  console.log(data);
  return (
    <>
      <div className="grid grid-cols-1 justify-center gap-4 w-full px-4 sm:px-12 sm:grid-cols-1 md:px-32 md:grid-cols-2 my-6 md:my-12 lg:grid-cols-3 xxlg:px-52 ">
        {data.map((article, index) => (
          <CategoryArticleCard key={index} article={article} category={category}/>
        ))}
      </div>
    </>
  );
};

export default CategoryArticles;
