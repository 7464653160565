import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { getCookie } from "../../common/utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PartnershipForm = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [cookie, setCookie] = useState(null);
  const [formData, setFormData] = useState({
    data: {
      name: "",
      phone_number: "",
      email: "",
      company_category: "",
      company_description: "",
    },
  });

  const [fieldValidity, setFieldValidity] = useState({
    name: true,
    phone_number: true,
    email: true,
    company_category: true,
    company_description: true,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone_number: "",
    email: "",
    company_category: "",
    company_description: "",
  });

  const [loading, setLoading] = useState(false);

  const formatPhoneNumber = (input) => {
    const numericInput = input.replace(/\D/g, "").slice(0, 10);
    const formatted = numericInput.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formatted;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      data: {
        ...formData.data,
        [name]: name === "phone_number" ? formatPhoneNumber(value) : value,
      },
    });

    setFieldValidity((prevValidity) => ({
      ...prevValidity,
      [name]: !!value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = Object.keys(formData.data).every(
      (key) => !!formData.data[key].trim()
    );

    if (isFormValid) {
      setLoading(true);
      const data = {
        ...formData,
        data: {
          ...formData.data,
          ...cookie,
        },
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_API_URL}/api/partner-requests`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
            },
            body: JSON.stringify(data),
          }
        );
        await response.json();
        setLoading(false);
        console.log(formData);
        console.log(cookie);
        setFormData({
          data: {
            name: "",
            phone_number: "",
            email: "",
            company_category: "",
            company_description: "",
          },
        });
        setErrorMessages({});
        setFieldValidity({
          name: true,
          phone_number: true,
          email: true,
          company_category: true,
          company_description: true,
        });
        setModalOpen(true);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      const updatedErrorMessages = {};
      Object.keys(formData.data).forEach((key) => {
        if (!formData.data[key].trim()) {
          switch (key) {
            case "name":
              updatedErrorMessages[key] = "Por favor, ingrese el nombre";
              break;
            case "phone_number":
              updatedErrorMessages[key] =
                "Por favor, ingrese el número de celular";
              break;
            case "email":
              updatedErrorMessages[key] =
                "Por favor, ingrese el correo electrónico";
              break;
            case "company_category":
              updatedErrorMessages[key] = "Por favor, seleccione una categoría";
              break;
            case "company_description":
              updatedErrorMessages[key] =
                "Por favor, ingrese una breve descripción de la empresa";
              break;
            default:
              updatedErrorMessages[key] = "";
          }
        } else {
          updatedErrorMessages[key] = "";
        }
      });
      setErrorMessages(updatedErrorMessages);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const cookieValue = getCookie("ME_cookie");
    setCookie(cookieValue);
  }, []);

  return (
    <div className="contact-us bg-[#F0F5F9] py-16">
      <div className="wrapper mx-10 flex flex-col items-center gap-8 mdcustom:flex-row mdcustom:gap-40 tablet:mx-20 ">
        <div className="flex-1">
          <h2 className="text-black text-2xl font-bold sm:text-3xl mdcustom:text-3xl">
            {data?.Form_title}
          </h2>
          <p className="text-[#495057] font-normal text-lg mt-5">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data?.Form_description}
            </ReactMarkdown>
          </p>
        </div>
        <div className="flex-1 w-full">
          <form
            onSubmit={handleSubmit}
            className="w-full bg-white flex flex-col gap-4  p-6 shadow-form rounded-xl mdcustom:p-12"
          >
            <div className="input-box w-full ">
              <label htmlFor="">Nombre:</label>
              {/* Display error message below the input field */}
              {errorMessages.name && (
                <p className="error-message text-red-500 text-sm">
                  {errorMessages.name}
                </p>
              )}
              <input
                type="text"
                placeholder="John Doe"
                name="name"
                value={formData.data.name}
                onChange={handleInputChange}
                className={`w-full bg-[#F8FAFC] px-4 py-3 rounded outline-none ${errorMessages.name ? "border border-red-500" : ""
                  }`}
              />
            </div>
            <div className="input-box w-full">
              <label htmlFor="">Número de celular:</label>
              {errorMessages.phone_number && (
                <p className="error-message text-red-500 text-sm">
                  {errorMessages.phone_number}
                </p>
              )}
              <input
                type="tel"
                placeholder="(XXX) XXX - XXXX"
                name="phone_number"
                value={formData.data.phone_number}
                onChange={handleInputChange}
                style={{ color: "#000" }}
                className={`w-full bg-[#F8FAFC] px-4 py-3 rounded outline-none ${errorMessages.phone_number ? "border border-red-500" : ""
                  }`}
              />
            </div>
            <div className="input-box w-full">
              <label htmlFor="">Correo electrónico:</label>
              {errorMessages.email && (
                <p className="error-message text-red-500 text-sm">
                  {errorMessages.email}
                </p>
              )}
              <input
                type="email"
                placeholder="johndoe@gmail.com"
                name="email"
                value={formData.data.email}
                onChange={handleInputChange}
                className={`w-full bg-[#F8FAFC] px-4 py-3 rounded outline-none ${errorMessages.email ? "border border-red-500" : ""
                  }`}
              />
            </div>
            <div className="input-box w-full">
              <label htmlFor="select-box">
                ¿Qué categoría describe mejor a su empresa?
              </label>
              {errorMessages.company_category && (
                <p className="error-message text-red-500 text-sm">
                  {errorMessages.company_category}
                </p>
              )}
              <select
                id="select-box"
                placeholder="Selecciona una categoría"
                name="company_category"
                value={formData.data.company_category}
                onChange={handleInputChange}
                className={`w-full bg-[#F8FAFC] px-4 py-3 rounded text-[#B8BCCA] outline-none ${errorMessages.company_category ? "border border-red-500" : ""
                  }`}
              >
                <option value="" selected>
                  Selecciona una categoría
                </option>
                <option value="Alivio de deudas">Alivio de deudas</option>
                <option value="Ayuda con impuestos">Ayuda con impuestos</option>
                <option value="Ayuda con inmigración">Ayuda con inmigración</option>
                <option value="Ayuda con la reparación de crédito">Ayuda con la reparación de crédito</option>
              </select>
            </div>
            <div className="input-box w-full">
              <label htmlFor="">
                Por favor, de una breve introducción sobre su empresa
              </label>
              {errorMessages.company_description && (
                <p className="error-message text-red-500 text-sm">
                  {errorMessages.company_description}
                </p>
              )}
              <input
                type="text"
                placeholder="Mi empresa...."
                name="company_description"
                value={formData.data.company_description}
                onChange={handleInputChange}
                className={`w-full bg-[#F8FAFC] px-4 py-3 rounded outline-none ${errorMessages.company_description
                    ? "border border-red-500"
                    : ""
                  }`}
              />
            </div>
            <button
              type="submit"
              className={`bg-[#0A1E93] text-white rounded-md text-lg font-medium py-3 ${loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                }`}
              disabled={loading}
            >
              Aplique ahora
            </button>
          </form>
        </div>
      </div>
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
            style={{ position: "absolute", right: 12, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="flex flex-col items-center gap-6 !px-8 pb-11">
          <CheckCircleOutlineIcon className="!text-6xl text-[#0A1E93]" />
          <p className="text-[#00003A] font-semibold text-xl text-center">
            <strong>¡Gracias </strong>por querer ser parte de Mejor Empresa!
            Hemos recibido sus datos. Estaremos encantados de revisar su
            información.
          </p>
          <span className="text-[#00003A] font-normal text-lg">
            Pronto nos contactaremos con used.
          </span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PartnershipForm;
