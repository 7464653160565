import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const CategoryCard = ({ data }) => {
  return (
    <>
      <div className="flex justify-center tablet:justify-between gap-4 flex-wrap mdcustom:flex-nowrap">
        {data.map((item, index) => {
          return (
            <Link
              to={`/blog/categorias/${item?.attributes?.Slug}`}
              key={index}
              className="flex explore-box-bg relative items-center justify-center rounded-md overflow-hidden w-full tablet:w-[48%] mdcustom:w-[25%]"
            >
              <LazyLoadImage
                src={`${process.env.REACT_APP_STRAPI_API_URL}${item?.attributes?.Image?.data?.attributes?.url}`}
                alt="Alivio-de-deudas"
                className="w-full min-h-[212px] object-cover h-full"
              />
              <div className="text-xl font-bold text-white absolute text-center px-4 sm:px-2">
                {item?.attributes?.Title}
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default CategoryCard;
