import BlogFaqCard from "./BlogFaqCard";

const BlogFAQ = ({ question }) => {
// Function to remove duplicates based on the 'id' property
  const removeDuplicates = (array, key) => {
    const uniqueKeys = new Set();
    return array.filter(obj => {
      if (!uniqueKeys.has(obj[key])) {
        uniqueKeys.add(obj[key]);
        return true;
      }
      return false;
    });
  };


  let blogFAQuestions = [];
  question?.map((item, index) => ( item?.data?.map((inneritem, innerindex) => (blogFAQuestions.push(inneritem)))))

  // Remove duplicates based on the 'id' property
  blogFAQuestions = removeDuplicates(blogFAQuestions, 'id');
  return (
    <div className="gap-14">
      <div
        id="faq"
        className="flex justify-center items-center mt-16 text-2xl md:text-3xl font-semibold text-black"
      > {question?.length ? 'Preguntas frecuentes':''}
      </div>
      <div className="mt-10 gap-8">
        {blogFAQuestions?.map((item, index) => (
          <div className="mb-4" key={index}>
            <BlogFaqCard question={item} key={index}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogFAQ;
