import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/common/Loader";
import HeroSection from "../components/ranking/hero-section/HeroSection";
import CompanySection from "../components/ranking/company-section/CompanySection";
import NotFound from './NotFound';
import { Helmet } from 'react-helmet';

const NewRanking = () => {
  const [loading, setLoading] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState([]);
  // const [articleSectionData, setArticleSectionData] = useState([]);
  const [rankingData, setRankingData] = useState([]);
  const [pageTitle, setPageTitle] = useState("Mejor Empresa");
  const [pageKeywords, setPageKeywords] = useState(
    "React, JavaScript, Web Development, Keywords"
  );
  const [pageDescription, setPageDescription] = useState(
    "Mejor Empresa site is created using React JS and Strapi"
  );

  const [canonicalURL, setCanonicalURL] = useState([]);
  const [ogLocale, setOgLocale] = useState([]);
  const [ogType, setOgType] = useState([]);
  const [ogTitle, setOgTitle] = useState([]);
  const [ogDescription, setOgDescription] = useState([]);
  const [ogurl, setOgurl] = useState([]);
  const [ogSiteName, setOgSiteName] = useState([]);

  const [articlePublisher, setArticlePublisher] = useState([]);
  const [articleModifiedTime, setArticleModifiedTime] = useState([]);
  const [ogImage, setOgImage] = useState([]);
  const [ogImageWidth, setOgImageWidth] = useState([]);
  const [ogImageHeight, setOgImageHeight] = useState([]);
  const [ogImageType, setOgImageType] = useState([]);
  const [structuredData, setStructuredData] = useState([]);

  let { service } = useParams();
  let serviceName = service;

  const apiCall = async () => {
    setLoading(true);

    //API call for company category
    const heroSectionApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/company-categories?populate=Short_description,seo,Long_description&filters[Slug][$eqi]=${serviceName}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    const dataOne = await heroSectionApiResponse.json();
    setHeroSectionData(dataOne?.data?.[0]?.attributes);

    setPageTitle(dataOne?.data?.[0]?.attributes?.seo?.metaTitle)
    setPageKeywords(dataOne?.data?.[0]?.attributes?.seo?.keywords)
    setPageDescription(dataOne?.data?.[0]?.attributes?.seo?.metaDescription)

    setCanonicalURL(dataOne?.data?.[0]?.attributes?.seo?.canonicalURL)
    setOgLocale(dataOne?.data?.[0]?.attributes?.seo?.ogLocale)
    setOgType(dataOne?.data?.[0]?.attributes?.seo?.ogType)
    setOgTitle(dataOne?.data?.[0]?.attributes?.seo?.ogTitle)
    setOgDescription(dataOne?.data?.[0]?.attributes?.seo?.ogDescription)
    setOgurl(dataOne?.data?.[0]?.attributes?.seo?.ogurl)
    setOgSiteName(dataOne?.data?.[0]?.attributes?.seo?.ogSiteName)

    setArticlePublisher(dataOne?.data?.[0]?.attributes?.seo?.articlePublisher)
    setArticleModifiedTime(dataOne?.data?.[0]?.attributes?.seo?.articleModifiedTime)
    setOgImage(dataOne?.data?.[0]?.attributes?.seo?.ogImage)
    setOgImageWidth(dataOne?.data?.[0]?.attributes?.seo?.ogImageWidth)
    setOgImageHeight(dataOne?.data?.[0]?.attributes?.seo?.ogImageHeight)
    setOgImageType(dataOne?.data?.[0]?.attributes?.seo?.ogImageType)
    setStructuredData(dataOne?.data?.[0]?.attributes?.seo?.structuredData)

    //API call to show the articles under the ranking page based on the company category.

    // const articleSectionApiResponse = await fetch(
    //   `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-pages?populate=Blog_title,Image&filters[Blog_category][Title][$eqi]=${serviceName}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
    //     },
    //   }
    // );
    // const dataThree = await articleSectionApiResponse.json();
    // setArticleSectionData(dataThree?.data);

    // API call for all the required data present in the ranking page.

    const rankingPageData = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/ranking-lists?populate[Company_name][populate]=logo,Hero_Section&populate[Company_category][populate]=*&filters[Company_category][Slug][$eqi]=${serviceName}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const rankingPageApiData = await rankingPageData.json();
    setRankingData(rankingPageApiData?.data);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    apiCall();
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={pageKeywords} />
        <meta name="description" content={pageDescription} />
        {canonicalURL ? (
          <link rel="canonical" href={canonicalURL} />
        ) : (void 0)
        }

        {ogLocale ? (
          <meta property="og:locale" content={ogLocale} />
        ) : (void 0)
        }

        {ogType ? (
          <meta property="og:type" content={ogType} />
        ) : (void 0)
        }

        {ogTitle ? (
          <meta property="og:title" content={ogTitle} />
        ) : (void 0)
        }

        {ogDescription ? (
          <meta property="og:description" content={ogDescription} />
        ) : (void 0)
        }

        {ogurl ? (
          <meta property="og:url" content={ogurl} />
        ) : (void 0)
        }

        {ogSiteName ? (
          <meta property="og:site_name" content={ogSiteName} />
        ) : (void 0)
        }

        {ogType ? (
          <meta property="og:type" content={ogType} />
        ) : (void 0)
        }

        {articlePublisher ? (
          <meta property="article:publisher" content={articlePublisher} />
        ) : (void 0)
        }

        {articleModifiedTime ? (
          <meta property="article:modified_time" content={articleModifiedTime} />
        ) : (void 0)
        }

        {ogImage ? (
          <meta property="og:image" content={ogImage} />
        ) : (void 0)
        }

        {ogImageWidth ? (
          <meta property="og:image:width" content={ogImageWidth} />
        ) : (void 0)
        }

        {ogImageHeight ? (
          <meta property="og:image:height" content={ogImageHeight} />
        ) : (void 0)
        }

        {ogImageType ? (
          <meta property="og:image:type" content={ogImageType} />
        ) : (void 0)
        }

        {structuredData ? (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        ) : (void 0)
        }

      </Helmet>
      {!loading ? (
        <>
          {heroSectionData ? (
            <>
              <HeroSection data={heroSectionData} />
              <CompanySection data={heroSectionData} companyDetails={rankingData} />
            </>
          ) : (
            <NotFound />
          )
          }
        </>
      ) : (
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default NewRanking;
