import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import AuthorSection from "../author-section/AuthorSection";
import BlogFAQ from "../FAQ/BlogFAQ";
import "../../../styles/BlogContent.css";

const BlogContentSection = ({ blogContent, blogAuthor, blogFAQs }) => {
  const [finalBlogContent, setFinalBlogContent] = useState([]);

  let oneThirdCTABlogContent = blogContent?.split("[One_Third_CTA]");
  let twoThirdCTABlogContent = blogContent?.split("[Two_Third_CTA]");

  const apiCTACall = async (shortCode) => {
    const ctaSectionApiResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_API_URL}/api/blog-cta-banners?populate[Image][fields][0]=url&filters[Short_Code][$eqi]=${shortCode}`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );
    const oneThirdCTA = await ctaSectionApiResponse.json();

    let oneThirdCTAContent =
      '<div style="background-image:linear-gradient(0deg,rgba(52,58,64,.5),rgba(52,58,64,.5)),url(https://api.mejorempresa.com' +
      oneThirdCTA?.data?.[0]?.attributes?.Image?.data?.attributes?.url +
      ');" className="bg-cover bg-center custom_bg_pos py-28 sm:py-14 px-3 sm:px-5 text-center rounded-md"><div className="text-[28px] sm:text-[24px] font-bold text-white leading-9 mb-0 sm:mb-1">' +
      oneThirdCTA?.data?.[0]?.attributes?.Title +
      '</div><div className="text-[18px] text-white leading-6 mt-5 sm:mt-0">' +
      oneThirdCTA?.data?.[0]?.attributes?.Short_Description +
      '</div><a href="' +
      oneThirdCTA?.data?.[0]?.attributes?.Button_URL +
      '" className="inline-block text-[#00003A] text-base font-bold bg-white py-[15px] px-4 rounded-md mt-10 sm:mt-4 custom_max_btn leading-4" >' +
      oneThirdCTA?.data?.[0]?.attributes?.Button_Text +
      "</a></div>";
    blogContent = blogContent?.split(shortCode)?.join(oneThirdCTAContent);
    setFinalBlogContent(
      blogContent?.split(shortCode)?.join(oneThirdCTAContent)
    );
  };

  const replaceBlogContent = () => {
    setFinalBlogContent(blogContent);
    if (oneThirdCTABlogContent?.length > 1) {
      apiCTACall("[One_Third_CTA]");
    }
    if (twoThirdCTABlogContent?.length > 1) {
      apiCTACall("[Two_Third_CTA]");
    }
  };

  useEffect(() => {
    replaceBlogContent();
  }, []);

  return (
    <div className="w-full base:w-[71.8%]">
      <div className="blog-content">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {finalBlogContent}
        </ReactMarkdown>
      </div>
      <AuthorSection data={blogAuthor} />
      <BlogFAQ question={blogFAQs} />
    </div>
  );
};

export default BlogContentSection;
