import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const PartnershipReasonsCard = ({ data }) => {
  return (
    <div className="reasons-box flex flex-col gap-9 mt-11 hidden mdcustom:block">
      <div className="reason flex justify-center items-center  bg-white">
        <div className="img-box h-56 aspect-square rounded-l-lg">
          <img
            src={`${process.env.REACT_APP_STRAPI_API_URL}${data?.attributes?.Image?.data?.attributes?.url}`}
            alt="poster"
            className="h-full w-full object-cover rounded-l-lg"
          />
        </div>
        <div className="content px-16">
          <h3 className="text-lg text-[#00003A] text-lg font-bold">
            {data?.attributes?.Title}
          </h3>
          <p className="text-base text-[#00003ab0] font-normal mt-4 ">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data?.attributes?.Description}
            </ReactMarkdown>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PartnershipReasonsCard;
